import { useEffect, useState } from "react";
import { InvestorPortalApi } from "../Api/api.InvestorPortal";
import ReportsApi from "../Api/api.Reports";
import { InvestorsList, Loading } from "../Modelos/Investors.model";
import { IUseInvestorProps } from "./IUseInvestorPortal";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const useInvestors = (props: IUseInvestorProps) => {
  const { language, firstEntrance, setFirstEntrance } = props;
  const [investorsData, setInvestorsData] = useState<InvestorsList[]>([]);
  const [allInvestorsData, setAllInvestorsData] = useState<InvestorsList[]>([]);
  const [loading, setLoading] = useState<Loading>({table: false, chart: false });
  const [quarter, setQuarter] = useState<string>("");
  const [quarterDate, setQuarterDate] = useState<string>("");
  const [homeFlowsChart, setHomeFlowsChart] = useState<any>([]);
  const [selectedInvestorsFlowChart, setSelectedInvestorsFlowChart] = useState<number[]>([]);
  const [documentId, setDocumentId] = useState<number>(0);
  const [documentName, setDocumentName] = useState("");
  const [pagination, setPagination] = useState<{page: number, viewed: number, total: number}>({
	page: 0,
	viewed: 0,
	total: 0
  });
  const [moreInvestorsToSearch, setMoreInvestorsToSearch] = useState<number>(0);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  let investorsPerPage = 15;

  const navigate = useNavigate();

  useEffect(() => {
	//restart pagination
	let newPagination = { page: 0, viewed: 0, total: 0 };
	getFundFigures(newPagination);
  }, [language]);
  
  useEffect(() => {
    if(selectedInvestorsFlowChart.length > 0){
      getHomeFlowsChart();
    }else{
      setHomeFlowsChart([]);
    }
  }, [selectedInvestorsFlowChart]);

  const getHomeFlowsChart = async () => {
    setLoading({ ...loading, chart: true });
    await InvestorPortalApi.getFlowChart(selectedInvestorsFlowChart)
    .then((response: any) => {
      if (response.success) {
        if(response.data.length > 0){
          let newResponse = response.data.filter((item: any) => item.column !== "totalValue");
          let residualValue = response.data.find((item: any) => item.column === "residualValue");
          let distributionValue = response.data.find((item: any) => item.column === "distribution");

          if(residualValue && distributionValue){
            let newObject = {
              column: "totalValue",
              value1: null,
              value2: null,
              rpi: distributionValue.value2,
              dpi: (residualValue.value2 - distributionValue.value2)
            }
            newResponse.push(newObject);
          }
          setHomeFlowsChart(newResponse);
        }
      }
      setLoading({ ...loading, chart: false })
    })
    .catch((error: any) => {
      console.log(error);
    })
    .finally(() => setLoading({ ...loading, chart: false }));
  };

  const handleInvestorsCheckboxFlowChart = (checked: boolean,idInvestor: number) => {
    if (checked) {
      setSelectedInvestorsFlowChart([
        ...selectedInvestorsFlowChart,
        idInvestor,
      ]);
    } else {
      setSelectedInvestorsFlowChart(
        selectedInvestorsFlowChart.filter((item) => item !== idInvestor)
      );
    }
  };

  const getFundFigures = async (pag: any) => {
    setLoading({ ...loading, table: true });
	setSearchValue("");
	let page = pag.page + 1;
	setMoreInvestorsToSearch(0);
    await InvestorPortalApi.getQuarterInvestorData(page, investorsPerPage, language)
      .then((response: any) => {
        if (response.success) {
			setQuarterDate(response.data.quarterDate);
			setQuarter(response.data.quarter);
			setLoading({ ...loading, table: false });
			setFirstEntrance(false);
		  
			/* Pagination */
			if(page > 1){
			  	let newInvestorsData = mergeInvestorsData(investorsData, response.data.peips);
				setInvestorsData(newInvestorsData);
				setAllInvestorsData(newInvestorsData);
			} else {
				setInvestorsData(response.data.peips);
				setAllInvestorsData(response.data.peips);
			}

			let viewed = response.data?.investorCount > pag.viewed + investorsPerPage ? pag.viewed + investorsPerPage : response.data?.investorCount;
			setPagination({ page: page, total: response.data?.investorCount, viewed: viewed });
			
			let hasInvestors = response.data?.peips?.some((peip: any) => peip.investorsList.length > 0);
			if(!hasInvestors && firstEntrance){
				navigate('/Details?detailsBy=investors')
			}
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoading({ ...loading, table: false }));
  };

  const viewCapitalCall = async (idInvestor: number, date: string) => {
	window.open(`${window.location.origin}/Report/?type=capital-call&date=${date}&investor=${idInvestor}&lang=${language}`, '_blank')
	setLoading({ ...loading, table: false })
  };

  const filterInvestors = (value: string) => {
	setSearchValue(value);
	if (debounceTimer) {
	  clearTimeout(debounceTimer);
	}
  
	const newTimer = setTimeout(async () => {
	  if (value === "") {
		setInvestorsData(allInvestorsData);
		setMoreInvestorsToSearch(0)
	  } else {
		try {
		  setLoading({ ...loading, table: true });
		  const response: any = await InvestorPortalApi.getQuarterInvestorData(1, investorsPerPage, language, value);
		  if (response.success) {
			setInvestorsData(response.data.peips);
			setMoreInvestorsToSearch(response.data?.investorCount >= 20 ? response.data?.investorCount : 0);
		  }
		  setLoading({ ...loading, table: false });
		} catch (error: any) {
		  notification.error({ message: 'Error', description: 'Error getting investors' });
		  setLoading({ ...loading, table: false });
		}
	  }
	  setLoading({ ...loading, table: false });
	}, 1000); // 1 second debounce time
  
	setDebounceTimer(newTimer);
  };
  

  const mergeInvestorsData = (currentData: any[], newData: any[]) => {
    const mergedData = [...currentData];
    newData.forEach(newPeip => {
        const existingPeip = mergedData.find(peip => peip.peipId === newPeip.peipId);
        if (existingPeip) {
			// PEIP ya existe, fusionar investorsList
            newPeip.investorsList.forEach((newInvestor: any) => {
                const existingInvestor = existingPeip.investorsList.find((investor: any) => investor.investorId === newInvestor.investorId);
                if (existingInvestor) {
					 // Actualizar datos del inversor existente
                    Object.assign(existingInvestor, newInvestor);
                } else {
					// Agregar nuevo inversor
                    existingPeip.investorsList.push(newInvestor);
                }
            });
        } else {
			// Agregar nuevo PEIP
            mergedData.push(newPeip);
        }
    });

    return mergedData;
};


  return {
    investorsData,
    loading,
    viewCapitalCall,
    quarter,
    homeFlowsChart,
    selectedInvestorsFlowChart,
    handleInvestorsCheckboxFlowChart,
    quarterDate,
	documentId,
	documentName, 
	setDocumentName,
	setDocumentId,
    filterInvestors,
	pagination,
	getFundFigures,
	moreInvestorsToSearch,
	searchValue,
  };
};

export default useInvestors;
